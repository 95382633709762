// Here you can add other styles
.header-dropdown-link {
    display: inline-block;
    text-decoration: none;
    color: $gray-900;
    margin-left: 0.8rem;
    margin-top: 0.8rem;

    &:hover {
        text-decoration: none;
        color: $gray-900;
    }
}

// Spinner
.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: auto;
}

.lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #510077;
    border-color: #510077 transparent #510077 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.image-wrapper {
    position: relative;

    &:hover {
        .image-button,
        .avatar-image-button {
            opacity: 1;
        }
    }
}

.image-button,
.avatar-image-button {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.image-button {
    bottom: 2rem;
    right: 2rem;
}

.avatar-image-button {
    left: 2.5rem;
    top: 3rem;
}

.custom-avatar {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;

    &:hover {
        .avatar-image-button {
            opacity: 1;
        }
    }
}

.image-card-wrapper {
    width: 10rem;
    height: 10rem;
    border-radius: 2px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    figcaption {
        background-color: #510077;
        color: white;
        font-style: italic;
        font-size: smaller;
    }
    &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
}

.image-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
